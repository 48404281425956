import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

class Authenticate extends React.Component {
  render() {
    const {error, didSignOut} = this.props

    let message = 'You are not authorized to view this page!'

    if (didSignOut) {
      message = 'You are now logged out'
    }

    if (error) {
      message = error
    }

    return (
      <div className="authenticate">
        <span>{message}</span>
      </div>
    )
  }
}

Authenticate.propTypes = {
  didSignOut: PropTypes.bool,
  error: PropTypes.string,
}

export default Authenticate
