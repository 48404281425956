import React from 'react'
import PropTypes from 'prop-types'
import ReactToggle from 'react-toggle'

import './index.css'

const Toggle = ({label, ...rest}) => {
  return (
    <div className="toggle">
      {label && <span>{label}</span>}
      <ReactToggle {...rest} />
    </div>
  )
}

Toggle.propTypes = {
  label: PropTypes.string,
}

export default Toggle
