import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import './index.css'

const Button = ({label, type, onClick, to, className, disabled, primary, next, cancel}) => {
  const classNames = ['btn', className]

  if (primary) classNames.push('primary')
  if (next) classNames.push('next')
  if (cancel) classNames.push('cancel')
  if (disabled) classNames.push('disabled')

  const sharedProps = {
    className: classNames.join(' ').trim(),
  }

  if (to) {
    return (
      <Link
        {...sharedProps}
        to={to}
        onClick={disabled ? ((event) => event.preventDefault()) : onClick}
      >
        {label}
      </Link>
    )
  }

  return (
    <button
      {...sharedProps}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  next: PropTypes.bool,
  cancel: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  className: '',
  disabled: false,
  primary: false,
  next: false,
  cancel: false,
}

export default Button
