import React, { Component } from 'react'

import './index.css'

export class ManualUploadAssetHeader extends Component {
  render() {
    return (
      <div className="asset-entry-header">
        <h4>Edited photo</h4>
        <h4>Replacement</h4>
      </div>
    )
  }
}

export default ManualUploadAssetHeader
