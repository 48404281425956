import React, { useEffect } from 'react'
import { notification } from 'antd'

export interface VersionNotificationProps {
  display?: boolean
}

function reload(e: React.MouseEvent<HTMLAnchorElement>) {
  e.preventDefault()
  window.location.reload(true)
}

const VersionNotification = ({ display }: VersionNotificationProps) => {
  useEffect(() => {
    if (display) {
      const notificationConfig = {
        message: 'Utdatert versjon',
        description: (<>
          Du bruker en utdatert versjon av appen,
          vennligst <a href={window.location.href} onClick={reload}>oppfrisk siden</a> for
          å oppdatere.
          Videre bruk kan medføre feilmeldinger.
        </>),
        duration: null,
        key: 'versionWarning'
      }

      notification.warning(notificationConfig)
    }
  }, [display])

  return null
}

export default VersionNotification
