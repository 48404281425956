import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'

import RequestContext from '../RequestContext'
import Loader from '../Loader'

class Login extends React.Component {
  state = {
    isSingleSignOn: false,
    loading: false,
    error: null,
  }

  static contextType = RequestContext;

  async componentDidMount() {
    // Check if sign-on token is provided
    const token = this.props.match.params.token || (/token=([^&]*)/.exec(this.props.location.search) || [])[1]
    if (token) {
      const didSignIn = await this.signInByToken(token)
      if (!didSignIn) {
        this.setState({
          error: 'Kunne ikke logge inn. Forsøk med e-post og passord',
        })
      }
    }
  }

  getRequestHeaders = () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    if (this.context.sessionId) {
      headers['x-request-id'] = `${this.context.sessionId}-${Math.random().toString(36).substring(7)}`
    }
    if (this.context.audience) {
      headers['x-audience'] = this.context.audience
    }

    return headers
  }

  clearError = () => {
    this.setState({error: null})
  }

  requestOtp = (email) => {
    return fetch(`${window.ENV.GRAPHQL_URL}/auth/otp`, {
      method: 'POST',
      headers: this.getRequestHeaders(),
      body: JSON.stringify({
        email,
        aud: this.props.aud,
      })
    })
    .then(response => {
      if (response.ok) {
        return true
      }
      this.setState({error: 'Innlogging feilet'})
    })
  }

  signInByToken = (token) => {
    this.setState({isSingleSignOn: true, loading: true})
    return this.performAuthRequest({token})
  }

  signInByCredentials = ({email, password}) => {
    this.setState({loading: true})
    return this.performAuthRequest({email, password})
  }

  signInByOtp = ({email, otp}) => {
    this.setState({loading: true})
    return this.performAuthRequest({email, otp})
  }

  handleJWTResponse = ({JWT}) => {
    if (JWT) {
      this.props.onJwt(JWT)
    }
    else {
      this.setState({error: 'Feil e-postadresse og/eller passord', loading: false})
    }

    return JWT
  }

  performAuthRequest = (params) => {
    return fetch(`${window.ENV.GRAPHQL_URL}/auth/jwt`, {
      method: 'POST',
      headers: this.getRequestHeaders(),
      body: JSON.stringify({
        ...params,
        aud: this.props.aud,
      })
    })
    .then(body => body.json())
    .then(this.handleJWTResponse)
    .catch(() => {
      this.setState({error: 'Innlogging feilet', loading: false})
    })
  }

  render() {
    const LoginComponent = this.props.Component

    if (this.state.isSingleSignOn && !this.state.error) {
      return <Loader loading={this.state.loading} />
    }

    return (
      <LoginComponent
        error={this.state.error}
        loading={this.state.loading}
        clearError={this.clearError}
        requestOtp={this.requestOtp}
        signInByOtp={this.signInByOtp}
        signInByCredentials={this.signInByCredentials}
        didSignOut={get(this.props.location, 'state.didSignOut', false)}
      />
    )
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  onJwt: PropTypes.func.isRequired,
  aud: PropTypes.string.isRequired,
  storageKey: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
}

export default Login
