import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const TimelinePicker = ({children, current}) => {
  return (
    <ol className="timeline-picker ">
      {React.Children.map(children, (child, index) => (
        React.cloneElement(child, {active: index === current})
      ))}
    </ol>
  )
}

TimelinePicker.propTypes = {
  children: PropTypes.node,
}

TimelinePicker.Item = ({title, description, onClick, active}) => {
  return (
    <li>
      <button onClick={onClick ? onClick : null} className={active ? 'active' : null}>
        {title}
        {description && (
          <span>{description}</span>
        )}
      </button>
    </li>
  )
}

TimelinePicker.Item.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default TimelinePicker
