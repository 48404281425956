import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'

import {RemovePhotoButton} from './RemovePhotoButton'

import './index.css'

export class ManualUploadAssetEntry extends Component {
  static propTypes = {
    asset: PropTypes.object.isRequired,
    onAssetClick: PropTypes.func.isRequired,
    onHandleFileUpload: PropTypes.func.isRequired,
    onRemoveUploadedPhoto: PropTypes.func.isRequired,
    replacementFile: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  onAssetClick(evt, isOriginal) {
    evt.preventDefault()
    this.props.onAssetClick(isOriginal)
  }

  replacementContent() {
    const {replacementFile, isLoading} = this.props

    if (isLoading) {
      return (
        <div className="asset-loading">
          Loading...
        </div>
      )
    }

    if (replacementFile) {
      return (
        <div className="asset-photo">
          <div className="asset-photo-wrapper">
            <a
              href={replacementFile}
              target="_blank"
              rel="noopener noreferrer"
              title="Open uploaded photo"
              onClick={evt => this.onAssetClick(evt, false)}
            >
              <img src={replacementFile} alt="Replacement" />
            </a>
            <RemovePhotoButton onClick={this.props.onRemoveUploadedPhoto} />
          </div>
        </div>
      )
    }

    return (
      <input type="file" onChange={evt => this.props.onHandleFileUpload(evt.target.files[0])} />
    )
  }

  render() {
    return (
      <div className="asset-list-entry">
        <div className="asset-photo">
          <a
            href={get(this.props.asset.image, 'url')}
            target="_blank"
            rel="noopener noreferrer"
            title="Open original photo"
            onClick={evt => this.onAssetClick(evt, true)}
          >
            <img src={get(this.props.asset.image, 'thumbUrl')} alt="Original" />
          </a>
        </div>
        <div className="asset-info">
          {this.replacementContent()}
        </div>
      </div>
    )
  }
}

export default ManualUploadAssetEntry
