import React from 'react'
import {captureException} from '@sentry/browser'
import {Spin} from 'antd'
import {CloseCircleFilled} from '@ant-design/icons'
import useRaygun from 'shared/hooks/useRaygun'
import './index.css'

const config = window.ENV

export default function Loader({children = null, loading, error}) {
  const raygun = useRaygun()

  if (loading) {
    return (
      <div className="query-loading">
        <Spin />
      </div>
    )
  }

  if (error) {
    console.log(error)
    // TODO: remove this when all apps are moved to Raygun
    if (config.SENTRY_DSN) {
      captureException(error)
    }

    if (raygun) {
      raygun('send', error)
    }

    return (
      <div className="query-error">
        <div className="error-content">
          <span className="error-icon">
            <CloseCircleFilled />
          </span>
          <span className="error-text">
            Noe gikk galt!
          </span>
          <span className="description-text">
            En feil har oppstått og innholdet her kan ikke lastes inn.
            Last siden på nytt og førsøk på nytt. Vennligst kontakt Inviso om
            problemet vedvarer.
          </span>
        </div>
      </div>
    )
  }

  return children
}
