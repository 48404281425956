import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const DescriptionList = ({children, inline}) => {
  const classNames = ['description-list']
  if (inline) classNames.push('inline')

  return (
    <dl className={classNames.join(' ')}>
      {children}
    </dl>
  )
}

DescriptionList.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
}

export default DescriptionList
