import React, {useContext, useState, useEffect} from 'react'
import {ApolloProvider} from 'react-apollo'
import {NormalizedCacheObject} from 'apollo-cache-inmemory'
import {ApolloClient} from 'apollo-client'

import AuthenticationContext from './AuthenticationContext'
import RequestContext from './RequestContext'
import {client as createClient} from '../lib/graphql'

export interface AuthenticatedApolloProviderProps {
  customFetch?: WindowOrWorkerGlobalScope['fetch']
  children: React.ReactNode
}

function AuthenticatedApolloProvider({children, customFetch = fetch}: AuthenticatedApolloProviderProps) {
  const {jwt, signOut} = useContext(AuthenticationContext)
  const {audience, sessionId} = useContext(RequestContext)

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    setClient(createClient({
      onUnathorizedRequest: signOut,
      jwt,
      audience,
      sessionId,
      customFetch,
    }))
  }, [jwt, signOut, audience, sessionId, customFetch])

  if (!client) {
    return null
  }
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default AuthenticatedApolloProvider
