import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

class ErrorHandler extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    reporter: PropTypes.func,
    sessionId: PropTypes.string,
    user: PropTypes.object,
  }

  state = {
    error: null,
    errorInfo: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo})
    if (typeof this.props.reporter === 'function') {
      this.props.reporter(this.props.sessionId, error, errorInfo, this.props.user)
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="page-error">
          <div className="inner">
            <h1>NOE GIKK GALT</h1>
            <p>Vi beklager, noe gikk galt.</p>
            <h2>Du kan forsøke å:</h2>
            <ul>
              <li>Gå tilbake til <a href="/">hovedsiden</a></li>
              <li>Ta kontakt med oss på telefon 21 08 05 00</li>
            </ul>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorHandler
