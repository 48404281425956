import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const MultiplePhotosUploader = ({onHandleFilesUpload}) => {
  return (
    <div className="multiple-photos-uploader">
      <span className="label">Upload multiple photos at once:</span>
      <span className="description">
        (Photos will be matched by file names, so it's important to reuse
        the same names as downloaded in the ZIP-file)
      </span>
      <input
        multiple
        type="file"
        accept="image/jpeg"
        onChange={onHandleFilesUpload}
      />
    </div>
  )
}

MultiplePhotosUploader.propTypes = {
  onHandleFilesUpload: PropTypes.func.isRequired,
}

export default MultiplePhotosUploader
