import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {TextArea} from 'shared/components'

export default class TaskCommentInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    updateComment: PropTypes.func.isRequired,
  }

  onChange = (evt) => {
    this.props.updateComment(evt.target.value)
  }

  render() {
    return (
      <TextArea
        placeholder={!this.props.disabled ? 'Write general instructions here' : null}
        className="task-comment-input"
        onChange={this.onChange}
        disabled={this.props.disabled}
      />
    )
  }
}
