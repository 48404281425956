export default function createErrorReporter(user) {
  const raygunIsEnabled = typeof window.rg4js === 'function'
  if (user && user.id && raygunIsEnabled) {
    window.rg4js('setUser', {
      identifier: user.id,
      email: user.email,
      isAnonymous: false,
    });
  }

  return function reportError(sessionId, error, extra, user) {
    if (raygunIsEnabled) {
      window.rg4js('send', error)
    } else {
      console.log(
        'The following information would have been sent to the error reporter',
        {user},
        error
      )
    }
  }
}
