import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

const LinkButton = ({label, to, className, next, primary}) => {
  const classNames = ['btn', className]

  if (next) classNames.push('next')
  if (primary) classNames.push('primary')

  return (
    <Link className={classNames.join(' ').trim()} to={to}>
      {label}
    </Link>
  )
}

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  next: PropTypes.bool,
  primary: PropTypes.bool,
}

LinkButton.defaultProps = {
  className: '',
  next: false,
  primary: false,
}

export default LinkButton
