import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const TaskActions = ({children}) => {
  return (
    <div className="task-actions">
      {children}
    </div>
  )
}

TaskActions.propTypes = {
  children: PropTypes.node,
}

export default TaskActions
