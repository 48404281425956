import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'

import './index.css'

const EnhancedListEntry = ({image, profile, onAssetClick, onCheckChange, isChecked}) => {
  function onClick(e) {
    e.preventDefault()
    onAssetClick()
  }

  return (
    <div className="enhanced-list-entry">
      <a
        href={image.url}
        target="_blank"
        rel="noopener noreferrer"
        title="Open in fullscreen"
        onClick={onClick}
      >
        <img
          alt=""
          src={image.url}
        />
      </a>
      <div className="actions">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onCheckChange}
        />
        {profile}
      </div>
    </div>
  )
}

EnhancedListEntry.fragments = {
  image: gql`
    fragment Image on Image {
      url
    }
  `,
}

EnhancedListEntry.propTypes = {
    image: propType(EnhancedListEntry.fragments.image).isRequired,
    profile: PropTypes.string.isRequired,
    onAssetClick: PropTypes.func.isRequired,
    onCheckChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
}

export default EnhancedListEntry
