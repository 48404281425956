import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'
import PropTypes from 'prop-types'
import {propType} from 'graphql-anywhere'
import {graphql} from 'react-apollo'
import compose from 'lodash.flowright'
import gql from 'graphql-tag'
import {AppBar, TopActionsBar, TaskActions} from 'components'
import RadioGroup from 'components/RadioGroup'
import EnhancedListEntry from 'components/EnhancedListEntry'
import {Container, Button, PhotoGallery, Toggle} from 'shared/components'

import TaskListPage from './TaskListPage'

const SPACE_KEY = 32

const qaTaskFragment = gql`
  fragment AutoEnhanceQaTask on QaTask {
    id
    pendingCorrections {
      edges {
        node {
          id
          photoId
        }
      }
    }
  }
`
const editingTaskFragment = gql`
  fragment EditingTask on EditingTask {
    id
    assets {
      edges {
        node {
          id
          ... on PhotoAsset {
            photoId
            scope
            image {
              ...Image
            }
          }
        }
      }
    }
  }
  ${EnhancedListEntry.fragments.image}
`

/**
 * The list of assets we get receive from the editing task includes all assets
 * not just the ones marked for correction. This function filters that list by
 * comparing the the assets on the editing task to the pending corrections.
 */
function filterAssetsPendingCorrection(qaTask, assets) {
  const pendingCorrectionIds = qaTask.pendingCorrections.edges.map(({node: {photoId}}) => (photoId))
  const edges = assets.edges.filter(({node}) => {
    return node.scope === 'output' && pendingCorrectionIds.includes(node.photoId)
  })
  return edges.map(({node}) => (node))
}

/**
 * A EnhancedAsset (what we get back from the mutation) is not quite the same as
 * a PhotoAsset. To make dealing with the "original" profile which is really no
 * profile a bit easier we munge the assets from the editing task into something
 * that looks like an EnhancedAsset.
 */
function convertAssetsToEnhancedAssets(assets) {
  return assets.map(asset => ({id: asset.id, assetId: asset.id, image: asset.image, profile: null}))
}

/**
 * This component handles two lists of assets:
 *  * props.editingTask.assets: The list of "original" assets, displayed when
 *    state.showOriginal is true, or if the "original" profile is picked for an
 *    image (indicates no profile should be applied).
 *  * state.enhancedAssets: A list representing assets which have been enhanced,
 *    these are assets which have been returned from the autoEnhanceTaskMutation
 *    or a converted asset from the props.editingTask.assets list.
 * When this component is initially loaded it populates state.enhancedAssets
 * by converting all the props.editingTask.assets. Each mutation call then just
 * replaces assets in that list with the enhanced versions.
 */
export class AutoEnhanceTaskPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    availableProfiles: PropTypes.arrayOf(PropTypes.string),
    enhanceAssetsAsCorrectionMutation: PropTypes.func.isRequired,
    autoEnhanceMutation: PropTypes.func.isRequired,
    qaTask: propType(qaTaskFragment),
    editingTask: propType(editingTaskFragment),
  }

  static defaultProps = {
    availableProfiles: [],
  }

  state = {
    isLoading: false,
    isSubmitting: false,
    showOriginal: false,
    currentAction: null,
    enhancedAssets: null,
    isFullscreen: false,
    initialImageIndex: null,
    checkedAssets: {},
  }

  /**
   * When loading this component for the first time we want an "initial" state
   * where all assets are using the "original" profile.
   */
  static getDerivedStateFromProps(props, state) {
    if (state.enhancedAssets === null && props.editingTask && props.qaTask) {
      const assets = filterAssetsPendingCorrection(props.qaTask, props.editingTask.assets)
      const enhancedAssets = convertAssetsToEnhancedAssets(assets)
      if (assets.length) {
        return {
          ...state,
          enhancedAssets,
        }
      }
    }
    return null
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleGalleryKeyInput.bind(this))
  }

  closeFullscreen() {
    this.setState({isFullscreen: false})
  }

  onAssetClick(photoIndex) {
    this.setState({isFullscreen: true, initialImageIndex: photoIndex})
  }

  onAssetCheck(photoIndex, event) {
    const checkedAssets = {...this.state.checkedAssets}
    checkedAssets[photoIndex] = event.target.checked
    this.setState({checkedAssets})
  }

  toggleAllAssets(event) {
    const checkedAssets = {...this.state.checkedAssets}
    this.currentDispayedAssets.forEach((asset, index) => {
      checkedAssets[index] = event.target.checked
    })
    this.setState({checkedAssets})
  }

  toggleShowOriginal(event) {
    this.setState({showOriginal: event.target.checked})
  }

  approveEnhancement(assets) {
    const taskId = this.props.editingTask.id

    this.setState({isSubmitting: true})
    this.props.enhanceAssetsAsCorrectionMutation({
      variables: {
        input: {
          taskId,
          assets: assets
            .filter(asset => asset.profile)
            .map(asset => ({id: asset.assetId, profile: asset.profile})),
        }
      },
      refetchQueries: [{
        query: TaskListPage.queries.TaskListQuery,
      }],
    })
    .then(() => {
      this.setState({isSubmitting: false, currentAction: 'completed'})
    }).catch((error) => {
      console.log(error)
    })
  }

  createEnhancedAssets(assets, profile) {
    return this.props.autoEnhanceMutation({
      variables: {
        input: {
          assets: assets.map(a => ({id: a.id})),
          profile,
        }
      }
    }).then(({data: {AutoEnhanceAssets}}) => {
      return AutoEnhanceAssets.assets
    })
  }

  async changeAssetsProfile(profile, photoIndexes = []) {
    const assetsToEnhance = []
    photoIndexes.forEach((index) => {
      const asset = this.originalAssets[index]
      assetsToEnhance.push(asset)
    })

    if (assetsToEnhance.length === 0) {
      return
    }

    this.setState({isLoading: true})

    const newAssets = profile
      ? await this.createEnhancedAssets(assetsToEnhance, profile)
      : convertAssetsToEnhancedAssets(assetsToEnhance)

    const enhancedAssets = this.state.enhancedAssets.reduce((obj, asset) => {
      obj[asset.assetId] = asset
      return obj
    }, {})
    newAssets.forEach((asset) => {
      enhancedAssets[asset.assetId] = asset
    })
    this.setState({isLoading: false, enhancedAssets: Object.values(enhancedAssets)})
  }

  handleGalleryKeyInput(event) {
    const keyCode = event.which || event.keyCode
    if (this.state.isFullscreen && event.type === 'keyup' && keyCode === SPACE_KEY) {
      event.stopPropagation()
      this.setState({showOriginal: !this.state.showOriginal})
    }
  }

  get originalAssets() {
    if (this.props.qaTask && this.props.editingTask) {
      return filterAssetsPendingCorrection(this.props.qaTask, this.props.editingTask.assets)
    }

    return []
  }

  get currentDispayedAssets() {
    if (this.state.showOriginal) {
      return this.originalAssets
    }
    return this.state.enhancedAssets || []
  }

  get noAssetsAreCorrected() {
    return !this.currentDispayedAssets.some(asset => asset.profile)
  }

  get checkedAssetIndexes() {
    return Object.entries(this.state.checkedAssets).filter(([, value]) => value).map(([i]) => i)
  }

  get allAssetsChecked() {
    return this.checkedAssetIndexes.length === this.currentDispayedAssets.length
  }

  get anyAssetsChecked() {
    return this.checkedAssetIndexes.length > 0
  }

  get availableProfiles() {
    return [
      ...this.props.availableProfiles.map(p => [p, p]),
      [null, 'Original'],
    ]
  }

  renderToggleOriginal() {
    return (
      <Toggle
        label="Show original"
        checked={this.state.showOriginal}
        onChange={this.toggleShowOriginal.bind(this)}
        icons={false}
      />
    )
  }

  getPhotoCaption(photoIndex) {
    const asset = this.currentDispayedAssets[photoIndex]
    const {showOriginal, isLoading} = this.state
    return (
      <div className="enhance-actions">
        <RadioGroup>
          {this.availableProfiles.map(([profile, name]) =>
            <label key={profile}>
              <input
                type="radio"
                value={profile || ''}
                disabled={showOriginal || isLoading}
                checked={asset.profile === profile}
                onChange={this.changeAssetsProfile.bind(this, profile, [photoIndex])}
              />
              {name}
            </label>
          )}
        </RadioGroup>

        {this.renderToggleOriginal()}
      </div>
    )
  }

  showTaskOrLoading(qaTask) {
    const {
      isLoading,
      isSubmitting,
      isFullscreen,
      showOriginal,
      initialImageIndex,
    } = this.state

    if ((this.props.isLoading || isLoading) && !qaTask) {
      return <div>Loading</div>
    }

    if (!qaTask) {
      return <Redirect to="/" />
    }

    // Get a list of photo urls only. Will be fed to the photo gallery
    const photoUrls = this.currentDispayedAssets.map((asset) => (asset.image.url))

    if (!(this.currentDispayedAssets.length && photoUrls.length)) {
      return <div>Something has gone wrong loading the enhanced assets for this task!</div>
    }

    return (
      <div>
        <h2 className="heading">Auto enhanced photos</h2>
        <TopActionsBar>
          <div>
            <label>
              <input
                className="toggle-all-photos"
                type="checkbox"
                checked={this.allAssetsChecked}
                onChange={this.toggleAllAssets.bind(this)}
              />
              Toggle all
            </label>
          </div>
          <div>
            {this.availableProfiles.map(([profile, name]) =>
              <Button
                key={profile}
                label={name}
                disabled={!this.anyAssetsChecked || showOriginal || isLoading}
                onClick={this.changeAssetsProfile.bind(this, profile, this.checkedAssetIndexes)}
              />
            )}
          </div>

          {this.renderToggleOriginal()}
        </TopActionsBar>

        <div className="enhanced-list">
          {this.currentDispayedAssets.map((asset, i) => (
            <EnhancedListEntry
              key={asset.id}
              image={asset.image}
              profile={asset.profile || ''}
              isChecked={this.state.checkedAssets[i] || false}
              onAssetClick={this.onAssetClick.bind(this, i)}
              onCheckChange={this.onAssetCheck.bind(this, i)}
            />
          ))}
        </div>

        <TaskActions>
          <Button
            cancel
            label="Cancel"
            className="cancel-enhance-task-btn"
            to={`/tasks/${this.props.match.params.id}`}
            disabled={isSubmitting}
          />
          <Button
            next
            label="Deliver photos"
            className="approve-enhance-task-btn"
            disabled={isSubmitting || this.noAssetsAreCorrected}
            onClick={this.approveEnhancement.bind(this, this.currentDispayedAssets)}
          />
        </TaskActions>

        {isFullscreen &&
          <PhotoGallery
            loading={isSubmitting}
            images={photoUrls}
            initialImageIndex={initialImageIndex}
            onCloseRequest={this.closeFullscreen.bind(this)}
            handleKeyInput={this.handleGalleryKeyInput.bind(this)}
            onRenderCaption={this.getPhotoCaption.bind(this)}
          />
        }
      </div>
    )
  }

  render() {
    const taskPath = `/tasks/${this.props.match.params.id}`

    if (this.state.currentAction === 'completed') {
      return <Redirect to="/" />
    }

    return (
      <div>
        <AppBar
          title="Auto enhance"
          backLink={taskPath}
          user={this.props.user}
        />
        <Container>
          {
            this.showTaskOrLoading(this.props.qaTask)
          }
        </Container>
      </div>
    )
  }
}

const ENHANCE_ASSETS_AS_CORRECTION_MUTATION = gql`
  mutation EnhanceAssetsAsCorrection($input: EnhanceAssetsAsCorrectionInput!) {
    EnhanceAssetsAsCorrection(input: $input) {
      taskId
    }
  }
`

const AUTO_ENHANCE_MUTATION = gql`
  mutation AutoEnhanceAssets($input: AutoEnhanceAssetsInput!) {
    AutoEnhanceAssets(input: $input) {
      assets {
        id
        assetId
        profile
        image {
          ...Image
        }
      }
    }
  }
  ${EnhancedListEntry.fragments.image}
`

const INITIAL_QUERY = gql`
  query ProfilesQuery($id: ID!, $editId: ID!) {
    viewer {
      id
      name
    }
    admin {
      id
      autoEnhanceProfiles
      qaTask(id: $id) {
        ...AutoEnhanceQaTask
      }
      editingTask(id: $editId) {
        ...EditingTask
      }
    }
  }
  ${qaTaskFragment}
  ${editingTaskFragment}
`

export default compose(
  graphql(ENHANCE_ASSETS_AS_CORRECTION_MUTATION, {name: 'enhanceAssetsAsCorrectionMutation'}),
  graphql(AUTO_ENHANCE_MUTATION, { name: 'autoEnhanceMutation' }),
  graphql(INITIAL_QUERY, {
    options: ({match: {params:{id, editId}}}) => ({
      variables: {id, editId}
    }),
    props: ({ownProps, data}) => {
      const props = {
        isLoading: data.loading,
        ...ownProps
      }
      if (!data.loading) {
        props.availableProfiles = data.admin.autoEnhanceProfiles
        props.user = data.viewer
        props.qaTask = data.admin.qaTask
        props.editingTask = data.admin.editingTask
      }
      return props
    }
  }),
)(AutoEnhanceTaskPage)
