import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const Container = ({children, center}) => {
  const classNames = ['container']
  if (center) classNames.push('center')

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
}

Container.defaultProps = {
  center: true,
}

export default Container
