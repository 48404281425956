import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './index.css'

export class RemovePhotoButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  }

  onClick(e) {
    e.preventDefault()
    this.props.onClick()
  }

  render() {
    return (
      <button
        title="Remove image"
        className="removePhoto"
        onClick={evt => this.onClick(evt)}
      >x</button>
    )
  }
}

export default RemovePhotoButton
