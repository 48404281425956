import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'
import get from 'lodash.get'

import {TextArea, PhotoGallery} from 'shared/components'
import AssetListEntry from '../AssetListEntry'
import './index.css'

const ASSET_LIST_FRAGMENT = gql`
  fragment AssetList on AssetsConnection {
    edges {
      node {
        ...AssetEntry
      }
    }
  }
  ${AssetListEntry.fragments.asset}
`

class AssetList extends React.Component {
  static propTypes = {
    updateAssetComment: PropTypes.func,
    assets: propType(ASSET_LIST_FRAGMENT).isRequired,
    assetComments: PropTypes.object.isRequired,
  }

  static fragments = {
    assetList: ASSET_LIST_FRAGMENT,
  }

  state = {
    isFullscreen: false,
    initialImageIndex: null,
  }

  closeFullscreen = () => {
    this.setState({isFullscreen: false})
  }

  onAssetClick = (photoIndex, evt) => {
    evt.preventDefault()
    this.setState({isFullscreen: true, initialImageIndex: photoIndex})
  }

  onAssetComment = (asset, evt, comment) => {
    if (typeof this.props.updateAssetComment === 'function') {
      this.props.updateAssetComment(asset.id, comment)
    }
  }

  getImageCaption(photoIndex, {stopArrowPropagation}) {
    if (typeof this.props.updateAssetComment === 'function') {
      const assets = this.props.assets.edges.map(({node}) => (node))
      const asset = assets[photoIndex]
      return (
        <div className="gallery-caption-actions">
          <TextArea
            placeholder="Write instructions for this photo here"
            className="asset-comment-input"
            name={asset.id}
            value={get(this.props.assetComments[asset.id], 'comment', '')}
            onChange={this.onAssetComment.bind(this, asset)}
            onKeyDown={stopArrowPropagation}
            onKeyUp={stopArrowPropagation}
            rows={2}
          />
        </div>
      )
    }
  }

  render() {
    const {
      assets: {edges},
      updateAssetComment,
      customerComments,
      assetComments,
    } = this.props
    const {initialImageIndex, isFullscreen} = this.state

    if (edges && edges.length) {
      const images = edges.map(({node}) => (node.image.url))

      return (
        <div className="asset-list">
          {
            edges.map(({node: asset}, i) => (
              <AssetListEntry
                key={asset.id}
                asset={asset}
                customerComment={get(customerComments, asset.photoId, 'None')}
                assetComments={assetComments}
                updateAssetComment={updateAssetComment}
                onAssetClick={this.onAssetClick.bind(this, i)}
              />
            ))
          }

          {isFullscreen &&
            <PhotoGallery
              images={images}
              initialImageIndex={initialImageIndex}
              onCloseRequest={this.closeFullscreen}
              onRenderCaption={this.getImageCaption.bind(this)}
            />
          }
        </div>
      )
    }


    return <div>Something has gone wrong loading the assets for this task!</div>
  }
}

export default AssetList
