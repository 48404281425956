import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {MdArrowBack} from 'react-icons/md'

import {Heading} from '../'

import './index.css'

const AppBar = ({title, backLink, backTitle, children}) => {
  return (
    <nav className="app-bar">
      <div className="navigation-and-title">
        {!!backLink &&
          <Link
            to={backLink}
            title={backTitle}
            className="back-link"
          >
            <MdArrowBack className="app-bar-icon" />
          </Link>
        }
        <Heading>{title}</Heading>
      </div>

      {children}
    </nav>
  )
}

AppBar.propTypes = {
  title: PropTypes.string,
  backLink: PropTypes.string,
  backTitle: PropTypes.string,
  children: PropTypes.node,
}

AppBar.defaultProps = {
  backTitle: 'Go back',
}

export default AppBar
