import React, {useState} from 'react'
import {v4 as uuidV4} from 'uuid'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import Routes from './routes'
import Login from './routes/Authenticate'

import AppWithAuth from 'shared/components/AppWithAuth'
import UserContext from 'shared/components/UserContext'
import ErrorHandler from 'shared/components/ErrorHandler'
import RequestContext from 'shared/components/RequestContext'
import useVersionCheck from 'shared/hooks/useVersionCheck'
import VersionNotification from 'shared/components/VersionNotification'
import AuthenticatedApolloProvider from 'shared/components/AuthenticatedApolloProvider'
import createRaygunErrorReporter from 'shared/lib/errorReporterRaygun'

import 'react-image-lightbox/style.css'
import 'react-toggle/style.css'
import 'shared/base.css'

const audience = 'qa'
const sessionId = uuidV4()

function App() {
  const [user, setUser] = useState({id: null, email: null})
  const { customFetch, hasNewVersion } = useVersionCheck()
  
  const errorReporter = createRaygunErrorReporter(user)
  
  return (
    <RequestContext.Provider value={{audience, sessionId}}>
      <UserContext.Provider value={user}>
        <ErrorHandler
          reporter={errorReporter}
          sessionId={sessionId}
          user={user}
        >
          <BrowserRouter>
            <AppWithAuth
              options={{
                jwtAud: audience,
                jwtKey: 'inviso-qa-jwt',
              }}
              components={{
                Login,
              }}
            >
              <VersionNotification display={hasNewVersion} />
              <AuthenticatedApolloProvider customFetch={customFetch}>
                <Routes setUser={setUser} />
              </AuthenticatedApolloProvider>
            </AppWithAuth>
          </BrowserRouter>
        </ErrorHandler>
      </UserContext.Provider>
    </RequestContext.Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
