import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const Heading = ({children, tag}) => {
  return React.createElement(tag, {className: 'heading'}, children)
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
}

Heading.defaultProps = {
  tag: 'h1',
}

export default Heading
