import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const RadioGroup = ({children}) => {
  return (
    <div className="radio-group">
      {children}
    </div>
  )
}

RadioGroup.propTypes = {
  children: PropTypes.node,
}

export default RadioGroup
