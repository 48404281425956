import React from 'react'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'

import {Table} from 'shared/components'

import TaskListEntry from './TaskListEntry'

const TaskList = ({tasks: {edges}}) => {
  if (edges && edges.length) {
    return (
      <Table>
        <thead>
          <tr>
            <th>Inviso ref.</th>
            <th>Project</th>
            <th>Agent</th>
            <th>Customer</th>
            <th>Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {
          edges.map(({node: task}) => (
            <TaskListEntry
              key={task.id}
              task={task}
            />
          ))
        }
        </tbody>
      </Table>
    )
  }
  return <div>There are no tasks pending!</div>
}

TaskList.fragments = {
  taskList: gql`
    fragment TaskList on QaTasksConnection {
      edges {
        node {
          ...TaskEntry
        }
      }
    }
    ${TaskListEntry.fragments.task}
  `,
}

TaskList.propTypes = {
  tasks: propType(TaskList.fragments.taskList).isRequired
}

export default TaskList
