import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const TopActionsBar = ({children}) => {
  return (
    <div className="top-actions-bar">
      {children}
    </div>
  )
}

TopActionsBar.propTypes = {
  children: PropTypes.node,
}

export default TopActionsBar
