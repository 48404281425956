import React from 'react'

interface RequestContext {
  audience: string | null
  sessionId: string | null
}

const RequestContext = React.createContext<RequestContext>({
  audience: null,
  sessionId: null,
})

export default RequestContext
