/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'

const THIRTY_SECONDS = 30 * 1000

/** Options for `useVersionCheck` */
interface VersionCheckOptions {
  /**
   *  The url to check the version from.
   *  @defaultValue '/version.txt'
   */
  versionUrl?: string
  /**
   * The minimum amount of time to wait before doing a new check, in milliseconds.
   * @defaultValue 30000
   * */
  interval?: number
}

/**
 * Hook that returns a custom fetch function that does version checking.
 * 
 * @remarks
 * Because of the current implementation, this should not be used inside
 * a component that will re-render often, as it will lose its internal state.
 *
 * @param options Configuration options.
 */
export default function useVersionCheck({versionUrl = '/version.txt', interval = THIRTY_SECONDS}: VersionCheckOptions = {}) {
  const [hasNewVersion, setHasNewVersion] = useState(false)
  let isChecking = false
  let lastCheck: Date | undefined
  let version: string | undefined

  const customFetch = useCallback(async (uri: RequestInfo, options: RequestInit) =>  {
    const currentDate = new Date()
    const timeOutsideInterval = lastCheck && (currentDate.getTime() - lastCheck.getTime() >= interval)

    if (isChecking) return fetch(uri, options)

    if (!lastCheck || timeOutsideInterval) {
      isChecking = true
      
      const res = await fetch(versionUrl)
      const newVersion = await res.text()
      
      lastCheck = currentDate
      isChecking = false
      // there is a new version
      if (version && version !== newVersion) {
        setHasNewVersion(true)
      }
      version = newVersion
    }

    return fetch(uri, options)
  }, [versionUrl, interval])

  return { customFetch, hasNewVersion }
}
