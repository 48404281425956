import React from 'react'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'

import {Heading, DescriptionList, DescriptionListTerm} from 'shared/components'

const EstateSummary = ({estate}) => {
  return (
    <div className="estate-summary">
      <Heading tag="h2">{estate.streetAddress}, {estate.postalCode} {estate.postalArea}</Heading>
      <DescriptionList inline>
        <DescriptionListTerm term="Inviso ref.:">
          #{estate.invisoRef}
        </DescriptionListTerm>

        <DescriptionListTerm term="Agent:">
          {estate.broker.name}
        </DescriptionListTerm>

        <DescriptionListTerm term="Customer:">
          {estate.broker.customer.name}
        </DescriptionListTerm>
      </DescriptionList>
    </div>
  )
}

EstateSummary.fragments = {
  estate: gql`
    fragment Estate on Estate {
      streetAddress
      postalCode
      postalArea
      invisoRef
      broker {
        name
        customer {
          name
        }
      }
    }
  `,
}

EstateSummary.propTypes = {
  estate: propType(EstateSummary.fragments.estate).isRequired
}

export default EstateSummary
