import React from 'react'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'

import {LinkButton} from 'shared/components'

const TaskEntry = ({task}) => {
  return (
    <tr>
      <td>#{task.product.estate.invisoRef}</td>
      <td>
        {task.product.estate.streetAddress},&nbsp;
        {task.product.estate.postalCode}&nbsp;
        {task.product.estate.postalArea}
      </td>
      <td>
        {task.product.estate.broker.name}
      </td>
      <td>
        {task.product.estate.broker.customer.name}
      </td>
      <td>
        {task.pendingCorrections.edges.length} of {task.product.quantity}
      </td>
      <td className="align-right">
        <LinkButton
          next
          label="Go to correction"
          to={`/tasks/${task.id}`}
        />
      </td>
    </tr>
  )
}

TaskEntry.fragments = {
  task: gql`
    fragment TaskEntry on QaTask {
      id
      pendingCorrections {
        edges {
          node {
            id
            photoId
          }
        }
      }
      product {
        id
        estate {
          invisoRef
          streetAddress
          postalCode
          postalArea
          broker {
            name
            customer {
              name
            }
          }
        }
        ... on PhotoBasedProduct {
          quantity
        }
      }
    }
  `,
}

TaskEntry.propTypes = {
  task: propType(TaskEntry.fragments.task).isRequired,
}

export default TaskEntry
