import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const TextArea = ({ value, onChange, ...rest }) => {
  const onValueChange = (e) => {
    if (onChange) {
      onChange(e, e.target.value)
    }
  }

  return (
    <textarea
      {...rest}
      value={value}
      onChange={onValueChange}
    />
  )
}

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
}

TextArea.defaultProps = {
  rows: 4,
}

export default TextArea
