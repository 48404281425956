import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const Table = ({children}) => {
  return (
    <table className="table">
      {children}
    </table>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Table
