import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'react-apollo'
import {propType} from 'graphql-anywhere'
import gql from 'graphql-tag'

import {Container, Heading} from 'shared/components'

import {AppBar} from 'components'
import TaskList from 'components/TaskList'

const TASKLIST_QUERY = gql`
  query AppQaTaskQuery {
    viewer {
      id
      name
    }
    admin {
      id
      qaTasks {
        ...TaskList
      }
    }
  }
  ${TaskList.fragments.taskList}
`

export class TaskListPage extends Component {
  static propTypes = {
    tasksLoading: PropTypes.bool.isRequired,
    tasks: propType(TaskList.fragments.taskList),
  }

  static queries = {
    TaskListQuery: TASKLIST_QUERY,
  }

  render() {
    return (
      <div>
        <AppBar
          title="Pending corrections"
          user={this.props.user}
        />
        <Container>
          <Heading tag="h2">Pending corrections waiting for approval</Heading>
          {
            this.props.tasksLoading
              ? 'Loading...'
              : <TaskList tasks={this.props.tasks} />
          }
        </Container>
      </div>
    )
  }
}

export default graphql(TASKLIST_QUERY, {
  props: ({ownProps, data}) => {
    const props = {
      tasksLoading: data.loading,
      ...ownProps
    }
    if (!data.loading) {
      props.tasks = data.admin.qaTasks
      props.user = data.viewer
    }
    return props
  }
})(TaskListPage)
