import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'

import {AppBar, UserContext} from 'shared/components'

const InternalAppBar = (props) => {
  return (
    <AppBar {...props}>
      <Helmet>
        {props.title && <title>{props.title}</title>}
      </Helmet>

      <div className="navigation">
        <UserContext.Consumer>
          {({signOut}) =>
            <React.Fragment>
              {props.user && props.user.name &&
                <span>{props.user.name}</span>
              }
              {signOut &&
                <button
                  className="signout-btn"
                  onClick={(e) => {
                    e.preventDefault()
                    signOut()
                  }}
                >
                  Log out
                </button>
              }
            </React.Fragment>
          }
        </UserContext.Consumer>
      </div>
    </AppBar>
  )
}

InternalAppBar.propTypes = {
  title: PropTypes.string,
  user: PropTypes.object,
  backLink: PropTypes.string,
  backTitle: PropTypes.string,
}

export default InternalAppBar
