import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {Switch, Route, Redirect} from 'react-router-dom'
import {getSession, setSession, clearSession} from '../../lib/session'
import AuthenticationContext from '../AuthenticationContext'

import Login from './Login'

class AppWithAuth extends React.Component {
  constructor(props) {
    super(props)
    if (/^\/login\/.+/.test(window.location.pathname) || /[&?]token=.+/.test(window.location.search)) {
      clearSession(props.options.jwtKey)
    }
    this.state = {
      didSignOut: false,
      jwt: getSession(props.options.jwtKey),
    }
  }

  signout = () => {
    clearSession(this.props.options.jwtKey)
    this.setState({jwt: null, didSignOut: true})
  }

  signin = (jwt) => {
    setSession(this.props.options.jwtKey, jwt)
    this.setState({jwt})
  }

  render() {
    const {components, options, children, sessionId = null, publicRoutes = []} = this.props
    const {jwt, didSignOut} = this.state

    return (
      <React.Fragment>
        <Helmet
          defaultTitle="Inviso"
          titleTemplate="%s - Inviso"
        />

        <Switch>
          {publicRoutes.map((props, i) => (
            <Route key={i} {...props} />
          ))}

          <Route
            path="/login/:token?"
            render={(props) => {
              let postLoginPath = '/'
              if (props.location.state && props.location.state.from && props.location.state.from.pathname) {
                postLoginPath = props.location.state.from.pathname
              }
              if (jwt) {
                return (<Redirect to={postLoginPath} />)
              }
              return (
                <Login
                  {...props}
                  aud={options.jwtAud}
                  storageKey={options.jwtKey}
                  onJwt={this.signin}
                  sessionId={sessionId}
                  Component={components.Login}
                />
              )
            }}
          />
          <Route render={(props) => {
            if (jwt) {
              return (
                <AuthenticationContext.Provider value={{
                  signOut: this.signout,
                  jwt,
                }}>
                  {children}
                </AuthenticationContext.Provider>
              )
            }
            else {
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    search: window.location.search,
                    state: {
                      from: props.location,
                      didSignOut,
                    },
                  }}
                />
              )
            }
          }} />
        </Switch>
      </React.Fragment>
    )
  }
}

AppWithAuth.propTypes = {
  options: PropTypes.object,
  components: PropTypes.object,
  children: PropTypes.node,
  publicRoutes: PropTypes.array,
  sessionId: PropTypes.string,
}

export default AppWithAuth
