import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'
import PropTypes from 'prop-types'
import {graphql} from 'react-apollo'
import compose from 'lodash.flowright'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'
import get from 'lodash.get'

import {Container, Button} from 'shared/components'

import {AppBar, TopActionsBar} from 'components'
import TimelinePicker from 'components/TimelinePicker'
import EstateSummary from 'components/EstateSummary'
import AssetList from 'components/AssetList'
import TaskActions from 'components/TaskActions'
import TaskCommentInput from 'components/TaskCommentInput'

import TaskListPage from './TaskListPage'

const ACTION_CANCEL = 'cancel'
const ACTION_COMPLETE = 'complete'
const ACTION_MANUAL_UPLOAD = 'manual-upload'
const ACTION_AUTO_ENHANCE = 'auto-enhance'
const ACTION_UNMARK_CORRECTION = 'unmark-correction'
const ACTION_SEND_FOR_CORRECTION = 'send-for-correction'

const qaTaskFragment = gql`
  fragment QaTask on QaTask {
    id
    product {
      id
      estate {
        ...Estate
      }
    }
    pendingCorrections {
      edges {
        node {
          id
          photoId
          correctionComment
        }
      }
    }
    history {
      edges {
        node {
          id
          editType
          state
          assets {
            ...AssetList
          }
        }
      }
    }
  }
  ${EstateSummary.fragments.estate}
  ${AssetList.fragments.assetList}
`

function findLastDeliveredTaskIndexOfType(candidates = [], types = []) {
  // loop through the candidates backwards
  for (let i = candidates.length - 1; i >= 0; i--) {
    const task = candidates[i].node
    if (task.state === 'delivered' && types.includes(task.editType)) {
      return i
    }
  }

  return 0
}

function findLastTaskDeliveredByDeskIndex(candidates = []) {
  return findLastDeliveredTaskIndexOfType(candidates, ['edit', 'correction'])
}

export class TaskViewPage extends Component {
  static propTypes = {
    sendCorrectionMutation: PropTypes.func.isRequired,
    unmarkCorrectionMutation: PropTypes.func.isRequired,
    cancelEditingTaskMutation: PropTypes.func.isRequired,
    taskLoading: PropTypes.bool.isRequired,
    qaTask: propType(qaTaskFragment)
  }

  state = {
    currentAction: null,
    taskComment: '',
    assetComments: {},
    currentHistoryIndex: null
  }

  static getDerivedStateFromProps(props, state) {
    if (state.currentHistoryIndex === null && props.qaTask) {
      const currentHistoryIndex = findLastTaskDeliveredByDeskIndex(props.qaTask.history.edges)
      if (currentHistoryIndex !== null) {
        return {
          ...state,
          currentHistoryIndex,
        }
      }
    }
    return null
  }

  getAssets() {
    if (this.props.qaTask) {
      const pendingCorrectionIds = this.props.qaTask.pendingCorrections.edges.map(({node: {photoId}}) => (photoId))
      const edges = this.currentTask.assets.edges.filter(({node}) => {
        return node.scope === 'output' && pendingCorrectionIds.includes(node.photoId)
      })
      edges.sort(({node: nodeA}, {node: nodeB}) => {
        return nodeA.photoId.localeCompare(nodeB.photoId)
      })
      return {edges}
    }
  }

  get customerComments() {
    const comments = {}
    this.props.qaTask.pendingCorrections.edges.forEach(({node: {photoId, correctionComment}}) => {
      comments[photoId] = correctionComment
    })

    return comments
  }

  get currentTask() {
    const index = this.state.currentHistoryIndex
    return this.props.qaTask.history.edges[index].node
  }

  changeHistoryIndex = (currentHistoryIndex) => {
    this.setState({
      currentHistoryIndex
    })
  }

  updateComment = (taskComment) => {
    this.setState({
      taskComment
    })
  }

  updateAssetComment = (id, comment) => {
    const assetComments = {...this.state.assetComments}
    assetComments[id] = {
      id,
      comment
    }
    this.setState({
      assetComments,
    })
  }

  actionPermitted(action) {
    if (this.state.currentAction !== null) {
      return false
    }
    if (action === ACTION_UNMARK_CORRECTION) {
      return true
    }

    const qaTaskHistory = this.props.qaTask.history.edges

    if (action === ACTION_CANCEL) {
      const index = findLastDeliveredTaskIndexOfType(qaTaskHistory, ['correction', 'filter'])
      return !!index && this.currentTask === qaTaskHistory[index].node
    }
    const index = findLastTaskDeliveredByDeskIndex(qaTaskHistory)
    return this.currentTask === qaTaskHistory[index].node
  }

  sendForCorrection = (evt) => {
    evt.preventDefault()
    const assetComments = this.state.assetComments
    const assets = this.getAssets().edges.map(({node}) => ({
      id: node.id,
      comment: get(assetComments[node.id], 'comment'),
    }))

    const input = {
      taskId: this.currentTask.id,
      comment: this.state.taskComment,
      assets,
    }

    this.setState({currentAction: ACTION_SEND_FOR_CORRECTION})
    this.props.sendCorrectionMutation({
      variables: {
        input
      },
      refetchQueries: [{
        query: TaskListPage.queries.TaskListQuery,
      }],
    }).then(() => {
      this.setState({currentAction: ACTION_COMPLETE})
    }).catch(() => {
      this.setState({currentAction: null})
    })
  }

  unmarkCorrection = (evt) => {
    evt.preventDefault()
    this.setState({currentAction: ACTION_UNMARK_CORRECTION})
    this.props.unmarkCorrectionMutation({
      variables: {
        input: {
          qaTaskId: this.props.qaTask.id,
        }
      },
      refetchQueries: [{
        query: TaskListPage.queries.TaskListQuery,
      }],
    }).then(() => {
      this.setState({currentAction: ACTION_COMPLETE})
    }).catch(() => {
      this.setState({currentAction: null})
    })
  }

  cancelTask = () => {
    this.setState({currentAction: ACTION_CANCEL})
    this.props.cancelEditingTaskMutation({
      variables: {
        input: {
          qaTaskId: this.props.qaTask.id,
          editingTaskId: this.currentTask.id,
        }
      },
      refetchQueries: [{
        query: TaskListPage.queries.TaskListQuery,
      }],
    }).then(() => {
      this.setState({currentAction: ACTION_COMPLETE})
    }).catch(() => {
      this.setState({currentAction: null})
    })
  }

  showTaskOrRedirect = (qaTask) => {
    if (!qaTask) {
      return <Redirect to="/"/>
    }

    return [
      <EstateSummary
        key="estateSummary"
        estate={qaTask.product.estate}
      />,
      <TopActionsBar key="assetActions">
        <TimelinePicker current={this.state.currentHistoryIndex}>
          {qaTask.history.edges.map(({node}, index) =>
            <TimelinePicker.Item
              key={node.id}
              title={`${index + 1}. ${node.editType}`}
              description={`(${node.state})`}
              onClick={this.changeHistoryIndex.bind(this, index)}
            />
          )}
        </TimelinePicker>
      </TopActionsBar>,
      <AssetList
        key="assetList"
        assets={this.getAssets()}
        assetComments={this.state.assetComments}
        customerComments={this.customerComments}
        updateAssetComment={this.actionPermitted(ACTION_SEND_FOR_CORRECTION) ? this.updateAssetComment : null}
      />,
      <TaskCommentInput
        key="taskCommentInput"
        disabled={!this.actionPermitted(ACTION_SEND_FOR_CORRECTION)}
        updateComment={this.updateComment}
      />,
      <TaskActions key="taskActions">
        <Button
          label="Cancel editing task"
          className="cancel-task-btn"
          disabled={!this.actionPermitted(ACTION_CANCEL)}
          onClick={this.cancelTask}
        />
        <Button
          label="Manual upload"
          className="manual-upload-task-btn"
          to={`/tasks/${qaTask.id}/manual-upload/${this.currentTask.id}`}
          disabled={!this.actionPermitted(ACTION_MANUAL_UPLOAD)}
        />
        <Button
          label="Auto enhance"
          className="auto-enhance-task-btn"
          to={`/tasks/${qaTask.id}/auto-enhance/${this.currentTask.id}`}
          disabled={!this.actionPermitted(ACTION_AUTO_ENHANCE)}
        />
        <Button
          cancel
          label="Unmark for correction"
          className="reject-task-btn"
          disabled={!this.actionPermitted(ACTION_UNMARK_CORRECTION)}
          onClick={this.unmarkCorrection}
        />
        <Button
          next
          label={this.state.currentAction === 'approveTask' ? 'Sending...' : 'Send to production'}
          className="approve-task-btn"
          disabled={!this.actionPermitted(ACTION_SEND_FOR_CORRECTION)}
          onClick={this.sendForCorrection}
        />
      </TaskActions>
    ]
  }

  render() {
    if (this.state.currentAction === ACTION_COMPLETE) {
      return <Redirect to="/" />
    }

    return (
      <div>
        <AppBar
          title="Pending correction"
          backLink="/"
          user={this.props.user}
        />
        <Container>
          {
            this.props.taskLoading
              ? 'Loading...'
              : this.showTaskOrRedirect(this.props.qaTask)
          }
        </Container>
      </div>
    )
  }
}

const FETCH_TASK_QUERY = gql`
  query AppQaTaskQuery($id: ID!) {
    viewer {
      id
      name
    }
    admin {
      id
      qaTask(id: $id) {
        ...QaTask
      }
    }
  }
  ${qaTaskFragment}
`

const SEND_CORRECTION_MUTATION = gql`
  mutation SendTaskForCorrection($input: SendTaskForCorrectionInput!) {
    SendTaskForCorrection(input: $input) {
      id
    }
  }
`

const UNMARK_CORRECTION_MUTATION = gql`
  mutation UnmarkTaskForCorrection($input: UnmarkTaskForCorrectionInput!) {
    UnmarkTaskForCorrection(input: $input) {
      id
    }
  }
`

const CANCEL_EDITING_TASK_MUTATION = gql`
  mutation CancelEditingTask($input: CancelEditingTaskInput!) {
    CancelEditingTask(input: $input) {
      id
    }
  }
`

export default compose(
  graphql(FETCH_TASK_QUERY, {
    options: ({match: {params:{id}}}) => ({
      variables: {id}
    }),
    props: ({ownProps, data: {loading, admin, viewer}}) => {
      const props = {
        taskLoading: loading,
        ...ownProps
      }
      if (!loading) {
        props.qaTask = admin.qaTask
        props.user = viewer
      }
      return props
    }
  }),
  graphql(SEND_CORRECTION_MUTATION, { name: 'sendCorrectionMutation' }),
  graphql(UNMARK_CORRECTION_MUTATION, { name: 'unmarkCorrectionMutation' }),
  graphql(CANCEL_EDITING_TASK_MUTATION, { name: 'cancelEditingTaskMutation' }),
)(TaskViewPage)
