import React, {useEffect} from 'react'
import {Switch, Route} from 'react-router-dom'

import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'

import TaskListPage from './TaskListPage'
import TaskViewPage from './TaskViewPage'
import AutoEnhanceTaskPage from './AutoEnhanceTaskPage'
import ManualUploadTaskPage from './ManualUploadTaskPage'

const getUserQuery = gql`
  query getUser {
    admin {
      id
      administrator {
        id
        email
      }
    }
  }
`

const Routes = ({setUser}) => {
  const {loading, data} = useQuery(getUserQuery)
  useEffect(() => {
    if (!loading && data && data.admin) {
      const {id} = data.admin
      let email = null
      if (data.admin && data.admin.administrator) {
        email = data.admin.administrator.email
      }
      setUser({id, email})
    }
  }, [setUser, loading, data])

  return (
    <Switch>
      <Route path="/tasks/:id/auto-enhance/:editId" component={AutoEnhanceTaskPage} />
      <Route path="/tasks/:id/manual-upload/:editId/" component={ManualUploadTaskPage} />
      <Route path="/tasks/:id" component={TaskViewPage} />
      <Route path="/" component={TaskListPage} />
    </Switch>
  )
}

export default Routes
