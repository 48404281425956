import { ApolloClient } from 'apollo-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

let requestCount = 0

export function client({
  jwt = null,
  onUnathorizedRequest = null,
  audience,
  sessionId = null,
  uriPath = '',
  customFetch = fetch,
}) {
  const link = new HttpLink({
    uri: window.ENV.GRAPHQL_URL + uriPath,
    fetch: customFetch,
  })
  // enable support for https://github.com/apollographql/apollo-client-devtools
  const connectToDevTools = window.ENV.ENABLE_DEV_TOOLS || false

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const newHeaders = {
        ...headers,
        'x-audience': audience,
      }
      if (sessionId) {
        newHeaders['x-request-id'] = `${sessionId}-${requestCount++}`
      }
      if (jwt) {
        newHeaders.authorization = `Bearer ${jwt}`
      }
      return { headers: newHeaders }
    })
    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext()
    let requestId = null
    if (context && context.response) {
      const {
        response: { headers = null },
      } = context
      requestId = headers ? headers.get('x-request-id') : null
    }

    if (graphQLErrors) {
      console.warn('GraphQLErrors', graphQLErrors, { requestId })
    }

    if (networkError) {
      console.warn('NetworkError', networkError, { requestId })

      // eslint-disable-next-line no-magic-numbers
      if (networkError.statusCode === 401 && onUnathorizedRequest) {
        onUnathorizedRequest()
      }
    }
  })

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: 'INTERFACE',
            name: 'Product',
            possibleTypes: [
              { name: 'AdvertisingProduct' },
              { name: 'AreaPhotoProduct' },
              { name: 'CollageProduct' },
              { name: 'DigitalRetouchedFurnishingPhotoProduct' },
              { name: 'DigitalRetouchedRenovationPhotoProduct' },
              { name: 'Drone360Product' },
              { name: 'DronePhotoProduct' },
              { name: 'ElevatedPhotoProduct' },
              { name: 'EnergyLabelingProduct' },
              { name: 'EveningPhotoProduct' },
              { name: 'FacadePhotoProduct' },
              { name: 'FlexiblePremiumPhotoProduct' },
              { name: 'FloorPlansProduct' },
              { name: 'MeasurementProduct' },
              { name: 'PanoramaPhotoProduct' },
              { name: 'PlanDrawingProduct' },
              { name: 'PortraitPhotoProduct' },
              { name: 'PremiumPhotoProduct' },
              { name: 'RetouchedEveningPhotoProduct' },
              { name: 'SlideshowProduct' },
              { name: 'SlidesProduct' },
              { name: 'StandardPhotoProduct' },
              { name: 'StylingProduct' },
              { name: 'VideoProduct' },
              { name: 'VirtualTourProduct' },
              { name: 'VisoProduct' },
              { name: 'WalkthroughProduct' },
            ],
          },
          {
            kind: 'INTERFACE',
            name: 'AssetsBasedProduct',
            possibleTypes: [
              { name: 'AreaPhotoProduct' },
              { name: 'CollageProduct' },
              { name: 'DigitalRetouchedFurnishingPhotoProduct' },
              { name: 'DigitalRetouchedRenovationPhotoProduct' },
              { name: 'Drone360Product' },
              { name: 'DronePhotoProduct' },
              { name: 'ElevatedPhotoProduct' },
              { name: 'EnergyLabelingProduct' },
              { name: 'EveningPhotoProduct' },
              { name: 'FacadePhotoProduct' },
              { name: 'FlexiblePremiumPhotoProduct' },
              { name: 'FloorPlansProduct' },
              { name: 'MeasurementProduct' },
              { name: 'PanoramaPhotoProduct' },
              { name: 'PlanDrawingProduct' },
              { name: 'PortraitPhotoProduct' },
              { name: 'PremiumPhotoProduct' },
              { name: 'RetouchedEveningPhotoProduct' },
              { name: 'SlideshowProduct' },
              { name: 'SlidesProduct' },
              { name: 'StandardPhotoProduct' },
              { name: 'StylingProduct' },
              { name: 'VideoProduct' },
              { name: 'VirtualTourProduct' },
              { name: 'WalkthroughProduct' },
            ],
          },
          {
            kind: 'INTERFACE',
            name: 'InputAssetsBasedProduct',
            possibleTypes: [
              { name: 'EnergyLabelingProduct' },
              { name: 'FloorPlansProduct' },
              { name: 'PlanDrawingProduct' },
            ],
          },
          {
            kind: 'INTERFACE',
            name: 'PhotoBasedProduct',
            possibleTypes: [
              { name: 'AreaPhotoProduct' },
              { name: 'CollageProduct' },
              { name: 'DigitalRetouchedFurnishingPhotoProduct' },
              { name: 'DigitalRetouchedRenovationPhotoProduct' },
              { name: 'RetouchedEveningPhotoProduct' },
              { name: 'DronePhotoProduct' },
              { name: 'ElevatedPhotoProduct' },
              { name: 'EveningPhotoProduct' },
              { name: 'FacadePhotoProduct' },
              { name: 'PanoramaPhotoProduct' },
              { name: 'PremiumPhotoProduct' },
              { name: 'StandardPhotoProduct' },
              { name: 'PortraitPhotoProduct' },
              { name: 'FlexiblePremiumPhotoProduct' },
            ],
          },
          {
            kind: 'INTERFACE',
            name: 'PhotoEditingBasedProduct',
            possibleTypes: [
              { name: 'DronePhotoProduct' },
              { name: 'ElevatedPhotoProduct' },
              { name: 'EveningPhotoProduct' },
              { name: 'FacadePhotoProduct' },
              { name: 'PanoramaPhotoProduct' },
              { name: 'PremiumPhotoProduct' },
              { name: 'StandardPhotoProduct' },
              { name: 'PortraitPhotoProduct' },
              { name: 'FlexiblePremiumPhotoProduct' },
            ],
          },
          {
            kind: 'INTERFACE',
            name: 'Asset',
            possibleTypes: [
              { name: 'PhotoAsset' },
              { name: 'DocumentAsset' },
              { name: 'UrlAsset' },
              { name: 'TextAsset' },
              { name: 'SlidesAsset' },
              { name: 'AreaPhotoAsset' },
              { name: 'PlanDrawingCorrectionAsset' },
            ],
          },
          {
            kind: 'INTERFACE',
            name: 'BookableProduct',
            possibleTypes: [
              { name: 'DigitalRetouchedFurnishingPhotoProduct' },
              { name: 'DigitalRetouchedRenovationPhotoProduct' },
              { name: 'Drone360Product' },
              { name: 'DronePhotoProduct' },
              { name: 'ElevatedPhotoProduct' },
              { name: 'EveningPhotoProduct' },
              { name: 'FacadePhotoProduct' },
              { name: 'FlexiblePremiumPhotoProduct' },
              { name: 'MeasurementProduct' },
              { name: 'PanoramaPhotoProduct' },
              { name: 'PortraitPhotoProduct' },
              { name: 'PremiumPhotoProduct' },
              { name: 'StandardPhotoProduct' },
              { name: 'StylingProduct' },
              { name: 'VideoProduct' },
              { name: 'VirtualTourProduct' },
            ],
          },
        ],
      },
    },
  })

  return new ApolloClient({
    connectToDevTools,
    link: ApolloLink.from([authLink, errorLink, link]),
    cache: new InMemoryCache({ fragmentMatcher }),
  })
}
