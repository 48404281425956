import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'
import PropTypes from 'prop-types'
import {graphql, withApollo} from 'react-apollo'
import compose from 'lodash.flowright'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'
import {Container, Button, PhotoGallery, Toggle} from 'shared/components'
import {
  AppBar,
  ManualUploadAssetEntry,
  TaskActions,
  ManualUploadAssetHeader,
  MultiplePhotosUploader,
} from 'components'

import TaskListPage from './TaskListPage'

const SPACE_KEY = 32

const qaTaskFragment = gql`
  fragment ManualUploadQaTask on QaTask {
    id
    pendingCorrections {
      edges {
        node {
          id
          photoId
        }
      }
    }
  }
`

const editingTaskFragment = gql`
  fragment ManualUploadEditingTask on EditingTask {
    id
    assets {
      edges {
        node {
          id
          ... on PhotoAsset {
            scope
            photoId
            image {
              url
              thumbUrl
              filename
            }
          }
        }
      }
    }
  }
`

export class ManualUploadTaskPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    taskLoading: PropTypes.bool.isRequired,
    client: PropTypes.object.isRequired,
    qaTask: propType(qaTaskFragment),
    editingTask: propType(editingTaskFragment),
    submitAsCorrectionMutation: PropTypes.func.isRequired,
    createAssetDownloadMutation: PropTypes.func.isRequired,
  }

  state = {
    loadingAssets: {},
    isSubmitting: false,
    task: {},
    currentAction: null,
    uploadedFiles: {},
    showOriginal: true,
    isFullscreen: false,
    initialImageIndex: null,
  }

  componentDidMount() {
    // Adding an event listener on window as well as the Gallery,
    // as elements that become disabled in the gallery means focus
    // is no longer on any element in the gallery.
    window.addEventListener('keyup', this.handleGalleryKeyInput.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleGalleryKeyInput.bind(this))
  }

  replacementUrlForAssetId(assetId) {
    if (this.state.uploadedFiles[assetId]) {
      return this.state.uploadedFiles[assetId].url
    }
    return null
  }

  getAssets() {
    if (this.props.qaTask) {
      const pendingCorrectionIds = this.props.qaTask.pendingCorrections.edges.map(({node: {photoId}}) => (photoId))
      let assets = this.props.editingTask.assets.edges.map(({node}) => (node))
      assets = assets.filter(asset => {
        return asset.scope === 'output' && pendingCorrectionIds.includes(asset.photoId)
      })
      return assets.sort((nodeA, nodeB) => {
        return nodeA.photoId.localeCompare(nodeB.photoId)
      })
    }
  }

  uploadSuccessful({url, assetId, name}) {
    this.setState(({uploadedFiles, loadingAssets}) => {
      uploadedFiles[assetId] = {url, name}
      loadingAssets[assetId] = false
      return {
        uploadedFiles,
        loadingAssets,
      }
    })
  }

  uploadFailed(assetId, error) {
    console.log(error)
    this.setState((state) => ({
      loadingAssets: {...state.loadingAssets, [assetId]: false}
    }))
  }

  uploadFile({file, signedUrl, url, assetId}) {
    return fetch(signedUrl, {
      method: 'PUT',
      body: file
    }).then(response => {
      if (response.ok) {
        this.uploadSuccessful({url, assetId, name: file.name})
      }
      else {
        this.uploadFailed(assetId, new Error('Upload error'))
      }
    }).catch(error => this.uploadFailed(assetId, error))
  }

  getSignedUrl(assetId, fileType, fileName = null) {
    return this.props.client.query({
      query: SIGN_FILE_REQUEST_QUERY,
      variables: {
        clientQueryId: fileName,
        fileType,
      },
      fetchPolicy: 'no-cache'
    }).then(({data}) => {
      return data.admin.signedFileRequest
    }).catch(error => this.uploadFailed(assetId, error))
  }

  closeFullscreen() {
    this.setState({isFullscreen: false})
  }

  onAssetClick(photoIndex, isOriginal) {
    this.setState({
      isFullscreen: true,
      initialImageIndex: photoIndex,
      showOriginal: isOriginal,
    })
  }

  setPhotoIndex(photoIndex) {
    this.setState({photoIndex})
  }

  toggleShowOriginal(event) {
    this.setState({showOriginal: event.target.checked})
  }

  approveUploads() {
    this.setState({isSubmitting: true})

    const taskId = this.props.editingTask.id
    const assets = Object.entries(this.state.uploadedFiles).map(([assetId, {url}]) => ({id: assetId, url}))

    this.props.submitAsCorrectionMutation({
      variables: {
        input: {
          taskId,
          assets
        }
      },
      refetchQueries: [{
        query: TaskListPage.queries.TaskListQuery,
      }],
    })
    .then(() => {
      this.setState({isSubmitting: false, currentAction: 'completed'})
    }).catch((error) => {
      console.log(error)
    })
  }

  downloadAssets() {
    this.setState({isSubmitting: true})

    const taskId = this.props.editingTask.id
    const assetIds = this.getAssets().map(({id}) => (id))

    this.props.createAssetDownloadMutation({
      variables: {
        input: {
          taskId,
          assetIds,
        }
      }
    })
    .then(res => {
      const url = res.data.CreateAssetDownload.url
      this.setState({isSubmitting: false})
      window.location.href = url
    }).catch((error) => {
      console.log(error)
      this.setState({isSubmitting: false})
    })
  }

  handleGalleryKeyInput(event) {
    const keyCode = event.which || event.keyCode
    if (this.state.isFullscreen && event.type === 'keyup' && keyCode === SPACE_KEY) {
      event.stopPropagation()
      this.setState({showOriginal: !this.state.showOriginal})
    }
  }

  get noAssetsAreCorrected() {
    return !Object.values(this.state.uploadedFiles).length
  }

  getDuplicatedAssets() {
    const names = Object.values(this.state.uploadedFiles).map(f => f.name)
    return names.reduce((acc, el, i, arr) => {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) {
        acc.push(el)
      }
      return acc
    }, [])
  }

  handleMultipleFilesUpload(event) {
    const files = event.target.files
    if (files && files.length) {
      const assets = this.getAssets()
      Array.from(files).forEach((file) => {
        // Find an asset matching the file's name. It might have a prefix of an
        // integer and underscore, which should be ignored.
        const asset = assets.find(a => a.image.filename === file.name.replace(/^\d+_/, ''))
        if (asset) this.handleFileUpload(file, asset.id)
      })
    }
  }

  handleFileUpload(file, assetId) {
    this.setState((state) => ({
      loadingAssets: {...state.loadingAssets, [assetId]: true}
    }))
    this.getSignedUrl(assetId, file.type, file.name)
      .then(({signedUrl, url}) => {
        this.uploadFile({file, signedUrl, url, assetId})
      })
      .catch(error => this.uploadFailed(assetId, error))
  }

  removeUploadedPhoto(assetId) {
    const uploadedFiles = this.state.uploadedFiles
    delete uploadedFiles[assetId]
    this.setState({uploadedFiles})
  }

  renderCaption() {
    return (
      <div className="enhance-actions">
        <div>
          <Toggle
            label="Show original"
            checked={this.state.showOriginal}
            onChange={this.toggleShowOriginal.bind(this)}
            icons={false}
          />
        </div>
      </div>
    )
  }

  showTaskOrRedirect(qaTask) {
    if (!qaTask) {
      return <Redirect to="/"/>
    }

    const assets = this.getAssets()
    const duplicatedAssets = this.getDuplicatedAssets()

    const {isFullscreen, initialImageIndex, showOriginal} = this.state

    // Get a list of photo urls only. Will be fed to the photo gallery
    const photoUrls = assets.map((asset) => {
      if (showOriginal) {
        return asset.image.url
      }
      return this.replacementUrlForAssetId(asset.id) || ''
    })

    if (!(assets.length && photoUrls.length)) {
      return <div>Something has gone wrong loading the assets for this task!</div>
    }

    const anyAssetsLoading = Object.values(this.state.loadingAssets).some(a => !!a)

    return (
      <div>
        <h2 className="heading">Manually upload photos</h2>

        <MultiplePhotosUploader
          onHandleFilesUpload={this.handleMultipleFilesUpload.bind(this)}
        />

        {duplicatedAssets.length > 0 && (
          <p>
            <strong>Error!</strong>
            <br />
            The following photos are uploaded more than once: {duplicatedAssets.join(', ')}
          </p>
        )}

        <ManualUploadAssetHeader />

        <div className="asset-list">
          {assets.map((asset, i) => (
            <ManualUploadAssetEntry
              key={asset.id}
              asset={asset}
              isLoading={!!this.state.loadingAssets[asset.id]}
              onAssetClick={isOriginal => this.onAssetClick(i, isOriginal)}
              onHandleFileUpload={file => this.handleFileUpload(file, asset.id)}
              onRemoveUploadedPhoto={() => this.removeUploadedPhoto(asset.id)}
              replacementFile={this.replacementUrlForAssetId(asset.id)}
            />
          ))}
        </div>

        <TaskActions>
          <Button
            label="Download edited photos"
            className="download-btn"
            disabled={this.state.isSubmitting}
            onClick={this.downloadAssets.bind(this)}
          />
          <Button
            cancel
            label="Cancel"
            className="cancel-enhance-task-btn"
            to={`/tasks/${this.props.match.params.id}`}
            disabled={this.state.isSubmitting}
          />
          <Button
            next
            label="Deliver photos"
            className="approve-enhance-task-btn"
            disabled={this.state.isSubmitting || this.noAssetsAreCorrected || anyAssetsLoading || duplicatedAssets.length > 0}
            onClick={this.approveUploads.bind(this)}
          />
        </TaskActions>
        {isFullscreen &&
          <PhotoGallery
            images={photoUrls}
            initialImageIndex={initialImageIndex}
            onCloseRequest={this.closeFullscreen.bind(this)}
            handleKeyInput={this.handleGalleryKeyInput.bind(this)}
            onRenderCaption={this.renderCaption.bind(this)}
          />
        }
      </div>
    )
  }

  render() {
    const taskPath = `/tasks/${this.props.match.params.id}`

    if (this.state.currentAction === 'completed') {
      return <Redirect to="/" />
    }

    return (
      <div>
        <AppBar
          title="Manual upload"
          backLink={taskPath}
        />
        <Container>
          {
            this.props.taskLoading
              ? 'Loading...'
              : this.showTaskOrRedirect(this.props.qaTask)
          }
        </Container>
      </div>
    )
  }
}

const FETCH_TASKS_QUERY = gql`
  query AppQaTaskQuery($id: ID!, $editId: ID!) {
    admin {
      id
      qaTask(id: $id) {
        ...ManualUploadQaTask
      }
      editingTask(id: $editId) {
        ...ManualUploadEditingTask
      }
    }
  }
  ${qaTaskFragment}
  ${editingTaskFragment}
`

const SIGN_FILE_REQUEST_QUERY = gql`
  query SignFileRequestQuery($fileType: String!, $clientQueryId: ID) {
    admin {
      signedFileRequest(fileType: $fileType, clientQueryId: $clientQueryId) {
        signedUrl
        url
      }
    }
  }
`

const SUBMIT_AS_CORRECTION_MUTATION = gql`
  mutation SubmitAsCorrection($input: SubmitAsCorrectionInput!) {
    SubmitAsCorrection(input: $input) {
      taskId
    }
  }
`

const CREATE_ASSET_DOWNLOAD_MUTATION = gql`
  mutation CreateAssetDownload($input: CreateAssetDownloadInput!) {
    CreateAssetDownload(input: $input) {
      url
    }
  }
`

export default compose(
  withApollo,
  graphql(FETCH_TASKS_QUERY, {
    options: ({match: {params:{id, editId}}}) => ({
      variables: {id, editId}
    }),
    props: ({ownProps, data: {loading, admin}}) => {
      const props = {
        taskLoading: loading,
        ...ownProps
      }
      if (!loading) {
        props.qaTask = admin.qaTask
        props.editingTask = admin.editingTask
      }
      return props
    }
  }),
  graphql(SUBMIT_AS_CORRECTION_MUTATION, {name: 'submitAsCorrectionMutation'}),
  graphql(CREATE_ASSET_DOWNLOAD_MUTATION, {name: 'createAssetDownloadMutation'}),
)(ManualUploadTaskPage)
