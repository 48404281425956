import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import {propType} from 'graphql-anywhere'
import get from 'lodash.get'
import {TextArea} from 'shared/components'

import './index.css'

export default class AssetListEntry extends Component {
  static fragments = {
    asset: gql`
      fragment AssetEntry on Asset {
        id
        ... on PhotoAsset {
          photoId
          scope
          image {
            url
            thumbUrl
            filenameIndex
            name
          }
        }
      }
    `,
  }

  static propTypes = {
    updateAssetComment: PropTypes.func,
    asset: propType(AssetListEntry.fragments.asset).isRequired,
    onAssetClick: PropTypes.func,
    assetComments: PropTypes.object.isRequired,
  }

  onChange = (evt) => {
    if (typeof this.props.updateAssetComment === 'function') {
      this.props.updateAssetComment(evt.target.name, evt.target.value)
    }
  }

  onAssetClick = (evt) => {
    if (this.props.onAssetClick) {
      this.props.onAssetClick(evt, this.props.asset)
    }
  }

  get imageName() {
    const {name, filenameIndex} = this.props.asset.image
    let imageName = ''
    if (name) {
      imageName += `${name} `
    }
    if (filenameIndex) {
      imageName += `#${filenameIndex}`
    }
    return imageName
  }

  get hasImageName() {
    const {name, filenameIndex} = this.props.asset.image
    return !!(name || filenameIndex)
  }

  render() {
    const {asset} = this.props
    const thumbUrl = get(asset, 'image.thumbUrl')

    return (
      <div className="asset-list-entry">
        <div className="asset-photo">
          <a
            href={get(asset.image, 'url', '#')}
            target="_blank"
            rel="noopener noreferrer"
            title="Open original photo"
            onClick={this.onAssetClick}
          >
            <img alt="" src={thumbUrl} />
          </a>
        </div>
        <div className="asset-info">
          <div className="asset-headers">
            <div className="asset-header">
              <span className="asset-title">Customer comment:</span>
              <span> {this.props.customerComment}</span>
            </div>
            {this.hasImageName ?
              (
                <div className="asset-header">
                  <span className="asset-title">Image name:</span>
                  <span className="asset-image-name">{this.imageName}</span>
                </div>
              )
            : null}
          </div>
          <TextArea
            placeholder={!!this.props.updateAssetComment ? 'Write instructions for this photo here' : null}
            className="asset-comment-input"
            disabled={!this.props.updateAssetComment}
            name={asset.id}
            value={get(this.props.assetComments[asset.id], 'comment', '')}
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }
}
