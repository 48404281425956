import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const DescriptionListTerm = ({term, children}) => {
  return (
    <div className="description-list-term">
      <dt>{term}</dt>
      <dd>{children}</dd>
    </div>
  )
}

DescriptionListTerm.propTypes = {
  term: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default DescriptionListTerm
